import { graphql } from 'gatsby'
import moment from 'moment'
import React from 'react'
import Layout from '../components/Layout'
import TalkInfo from '../components/Talk/TalkInfo'
import { SEO } from '../components/SEO'
import { Image } from '../components/Image'

class TalkTemplate extends React.Component {
  render() {
    const {
      data: {
        markdownRemark: {
          frontmatter,
          fields: { slug },
          html,
        },
      },
    } = this.props

    const {
      title: pageTitle,
      description: pageDescription,
      date,
      organizerLogo,
      location,
      video,
      slide,
      info,
      images,
    } = frontmatter
    const featureImage = slug.substring(0, slug.length - 1)
    const imagesToRender = images ?? [0]

    return (
      <Layout>
        <React.Fragment>
          <SEO pageTitle={pageTitle} pageDescription={pageDescription} />
          <div className="content">
            <div className="content__inner">
              <div className="page">
                <h1 className="page__title">{pageTitle}</h1>
                <h2
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    alt=""
                    className="talk-logo"
                    src={`/img/speaking/logos/logo-${organizerLogo}`}
                    style={{
                      width: '3rem',
                      flexShrink: 0,
                      marginRight: '1rem',
                    }}
                  />
                  {location}, {moment(date).format('D MMM YYYY')}
                </h2>
                <div style={{ padding: '1rem 0' }}>
                  <TalkInfo
                    video={video}
                    slide={slide}
                    info={info}
                    isFeatured
                  />
                </div>
                <div
                  className="page__body"
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                {imagesToRender.map(image => (
                  <Image
                    className="talk-image mb-4"
                    src={`/img/speaking/${featureImage}-0${image}.jpg`}
                    options='format=auto,width=1280'
                    alt={pageTitle}
                  />
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>
      </Layout>
    )
  }
}

export default TalkTemplate

export const pageQuery = graphql`
  query TalkBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        updated
        organizerLogo
        location
        video
        slide
        info
        images
      }
      fields {
        tagSlugs
        slug
      }
    }
  }
`
